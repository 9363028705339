
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import UserViewService, { UserViewServiceS } from '@/modules/user/user-view.service';
import type UserService from '@/modules/user/user.service';

@Component
export default class InsightsClusterHotelItem extends Vue {
    @inject(UserViewServiceS) private userViewService!: UserViewService;
    @inject(KEY.UserService) private userService!: UserService;

    toHotel() {
        this.userViewService.goToHotelPage(this.userService.currentHotelId!);
    }
}
